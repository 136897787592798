import $ from 'jquery'
import 'core-js/es/number'
import Swiper, { Navigation, A11y, Pagination, EffectCreative } from 'swiper'
import 'swiper/swiper-bundle.css'

class SliderCustomPostTypes extends window.HTMLDivElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.props = this.getInitialProps()
    this.resolveElements()
  }

  getInitialProps () {
    let data = {}
    try {
      data = JSON.parse($('script[type="application/json"]', this).text())
    } catch (e) {}
    return data
  }

  resolveElements () {
    this.$slider = $('[data-slider]', this)
    this.$buttonNext = $('[data-slider-button="next"]', this)
    this.$buttonPrev = $('[data-slider-button="prev"]', this)
  }

  connectedCallback () {
    this.initSlider()
  }

  initSlider () {
    const { options } = this.props
    const config = {
      modules: [Navigation, A11y, Pagination, EffectCreative],
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: function (index, className) {
          return '<button class="button ' + className + '">' + (index += 1) + '</button>'
        }
      },
      a11y: options.a11y,
      effect: 'creative',
      creativeEffect: {
        prev: {
          // will set `translateZ(-400px)` on previous slides
          translate: [0, 0, -2000],
          opacity: 0
        },
        next: {
          // will set `translateX(100%)` on next slides
          translate: ['150%', 0, 0]
        }
      },
      speed: 600,
      on: {
        reachBeginning: () => {
          this.$buttonPrev.addClass('swiper-button-disabled')
        },
        reachEnd: () => {
          this.$buttonNext.addClass('swiper-button-disabled')
        },
        fromEdge: () => {
          this.$buttonPrev.removeClass('swiper-button-disabled')
          this.$buttonNext.removeClass('swiper-button-disabled')
        }
      }
    }
    this.slider = new Swiper(this.$slider.get(0), config)

    // Custom navigation logic
    this.$buttonNext.on('click', (event) => {
      event.preventDefault()
      this.slider.slideNext()
    })

    this.$buttonPrev.on('click', (event) => {
      event.preventDefault()
      this.slider.slidePrev()
    })
  }
}

window.customElements.define('flynt-slider-custom-post-types', SliderCustomPostTypes, { extends: 'div' })

// Network cpt modal

var modalBtns = [...document.querySelectorAll('.modal-open')]
modalBtns.forEach((btn) => {
  btn.onclick = () => {
    var modal = btn.getAttribute('data-modal-open')
    document.getElementById(modal).showModal()

    const slider = ($(btn).closest('[is="flynt-slider-custom-post-types"]')[0]).slider
    const closeButton = ($(btn).closest('[is="flynt-slider-custom-post-types"]').find('[data-modal-close]'))
    slider.disable()
    closeButton.trigger('blur')
  }
})

var closeBtns = [...document.querySelectorAll('.modal-close')]
closeBtns.forEach((btn) => {
  btn.onclick = () => {
    var modal = btn.getAttribute('data-modal-close')
    document.getElementById(modal).close()

    const slider = ($(btn).closest('[is="flynt-slider-custom-post-types"]')[0]).slider
    slider.enable()
  }
})
