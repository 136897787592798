import $ from 'jquery'

class TabsContacts extends window.HTMLDivElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.bindFunctions()
    this.bindEvents()
    this.resolveElements()
    this.$filter.find('[data-term]').first().trigger('click')
  }

  bindFunctions () {
    this.filterContacts = this.filterContacts.bind(this)
  }

  bindEvents () {
    this.$.on('click', '.menu-link', this.filterContacts)
  }

  resolveElements () {
    this.$filter = $('.filter', this)
    this.$contacts = $('.contacts', this)
  }

  filterContacts (e) {
    const $target = $(e.target)
    const filterTerm = $target.data('term')
    this.$filter.find('[data-term]').removeClass('active')
    $target.addClass('active')
    this.$contacts.find('.contact').hide().removeClass('active')
    this.$contacts.find(`.contact[data-term="${filterTerm}"]`).show().addClass('active')
  }
}

window.customElements.define('flynt-tabs-contacts', TabsContacts, { extends: 'div' })
