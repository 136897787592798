import $ from 'jquery'

class GridImages extends window.HTMLDivElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.bindFunctions()
    this.bindEvents()
    this.resolveElements()
  }

  bindFunctions () {
    this.toggleImage = this.toggleImage.bind(this)
  }

  bindEvents () {
    this.$.on('click', '.grid--image', this.toggleImage)
  }

  resolveElements () {
    this.$grid = $('.grid', this)
    this.$images = $('.grid--image', this)
  }

  toggleImage (e) {
    const $target = $(e.target)
    if ($target.parent().hasClass('active')) {
      $target.parent().removeClass('active')
    } else {
      $target.parent().addClass('active')
    }
  }
}

window.customElements.define('flynt-grid-images', GridImages, { extends: 'div' })
